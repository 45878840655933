import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const socialProfileEnv = (): string => {
  if (publicRuntimeConfig === undefined) {
    return "development";
  }
  return publicRuntimeConfig.SOCIAL_PROFILE_ENV === undefined
    ? "development"
    : (publicRuntimeConfig.SOCIAL_PROFILE_ENV as string);
};

export const isStaging = (): boolean => {
  return publicRuntimeConfig.SOCIAL_PROFILE_ENV === "staging";
};

export const isDevelopment = (): boolean => {
  return (
    publicRuntimeConfig.SOCIAL_PROFILE_ENV === "development" || publicRuntimeConfig.SOCIAL_PROFILE_ENV === undefined
  );
};

export const isProduction = (): boolean => {
  return publicRuntimeConfig.SOCIAL_PROFILE_ENV === "production";
};
